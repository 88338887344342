:root{
    --background: #050505;
    
    --background-chapter-select-icons: #fff3;
    --background-chapter-select-icons-hover: #fff5;

    --text: #eee;
    --text-soft: #999;

    --link: #a06dd3;
}

.text{
    color: var(--text) !important;
}

.text-soft{
    color: var(--text-soft) !important;

}

.text-link{
    color: var(--link) !important;
    cursor: pointer;
}