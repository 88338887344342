


#ChapterSelect a{
    color:var(--text)  !important;
    margin: 0 7px;
    background-color: var(--background-chapter-select-icons);

    transition: background-color .25s;
}

#ChapterSelect a:hover{
    background-color: var(--background-chapter-select-icons-hover);

}

#ComicImage{
    max-width: 55vw;
}

@media (max-width: 768px)  {
    #ComicImage{
        max-width: 90vw;
    }
    
}