.App {
  font-family: Helvetica, sans-serif;
}

a{
    color: var(--link) !important;
    text-decoration: none !important;
}

.BackgroundImage{
    position: fixed;
    min-height: 140vh;
    max-height: 140vh;
    max-width: 20vw;
    top: -20vh;
}

#Logo{
    max-width: 100vw;
}

#nav{
    display: block;

    margin: auto;

    min-height: fit-content;
    height: 30px;

    background-color: var(--background);
    padding: 0;
}

#nav .navlink{
    color: var(--text) !important;
    text-decoration: none;
    cursor: pointer;

    text-transform: uppercase;

    transition: all .25s;
}

#nav a.navlink{
    line-height: 2rem;
}

#nav .navlink:hover{
    background-color: var(--background-chapter-select-icons);
}

small{
    overflow-wrap: normal;
    margin: auto 0;
}

footer small:not(:last-child)::after{
    content: " | ";
    margin: 0px 6px;
}

@media (max-width: 768px)  {

    footer small:not(:last-child)::after{
        content: " ";
    }
    small{
        overflow-wrap: normal;
        margin: 5px auto;
    }
    
}